import React from 'react';
import { Link as RLink } from 'react-router-dom';
import { Avatar, Button, Box } from '@mui/material';
import SlackIcon from '../components/SlackIcon';

// AuthZ Hierarchy
// puffdirectory-admins: can edit/troubleshoot all services (isPuffdirAdmin)
// owner of service : can edit/troubleshoot his own service (isOwnerOrAdmin)

export function isOwnerOrAdmin(service) {
  // User is authorized on a service (or team) when he has a can_edit property (from puffdirectory API):
  // A user (or manager) has can_edit == true if he owns the service, or if he is a manager of a service he owns
  // alternatively, members of the OAuth2 group "puffdirectory-admins" are also automatically authorized.
  return service.can_edit || false;
}

export function formatTeamSlug(teamSlug) {
  if (!teamSlug) {
    return '';
  }
  teamSlug = teamSlug.replace('-', ' ');

  if (teamSlug.length <= 4) {
    return teamSlug.toUpperCase();
  } else {
    return teamSlug.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}

export const resourceLifecycleMap = {
  uat: 'Staging',
  dev: 'Development',
  prod: 'Production',
};

export function isPuffdirAdmin(auth) {
  // Check if user is a member of the OAuth2 group "puffdirectory-admins"
  // This is useful for feature flags, or for hiding/showing certain UI elements
  const resourceAccess = auth.userData.profile.resource_access;
  if (!resourceAccess || !Array.isArray(resourceAccess)) {
    return false;
  }
  for (let i = 0; i < resourceAccess.length; i++) {
    const resource = resourceAccess[i];
    if (
      resource.hasOwnProperty('puffdirectory') &&
      Array.isArray(resource.puffdirectory.roles) &&
      resource.puffdirectory.roles.includes('puffdirectory-admins')
    ) {
      return true;
    }
  }
}

export const cluster_argo = {
  // Endpoints for argo
  sre: 'https://argo.sre.gopuff.com',
  splat: 'https://argo.platform-internal.gopuff.com',
  devops: 'https://argo.devops.gopuff.com',
  coreinfra: 'https://argo.gopuff.tech',
};

export const statusColor = {
  Active: '#25b225',
  Experimental: '#ce44ec',
  Deprecated: '#da4103',
};
